import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import {
  HouseOptionValueExceptionId,
  useCreateHouseOptionValueException,
  useDeleteHouseOptionValueException,
  useGetHouseOptionValueExceptions,
  useUpdateHouseOptionValueException
} from '@api/endpoints/buildings/houses'
import { LayoutTypeOptionValueId } from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Delete, Edit, Sync } from '@mui/icons-material'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { ButtonWithEditPriceModalForm } from '@pages/Admin/Houses/HouseForm/HouseValueExceptions/ButtonWithEditPriceModalForm'
import { useHouseFormContext } from '../HouseFormContext'
import { HouseValueExceptionsStyled } from './HouseValueExceptions.styles'

export const HouseValueExceptions: React.FC = () => {
  const { t } = useTranslation()
  const { house, layoutType } = useHouseFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const translateValue = useTranslatedValue()

  const createHouseValueException = useCreateHouseOptionValueException({ onError: defaultErrorHandler })
  const updateHouseValueException = useUpdateHouseOptionValueException({ onError: defaultErrorHandler })
  const deleteHouseValueException = useDeleteHouseOptionValueException({ onError: defaultErrorHandler })
  const getHouseValueExceptions = useGetHouseOptionValueExceptions(house.id, { onError: defaultErrorHandler })

  const onDelete =
    (layoutTypeOptionValueId: LayoutTypeOptionValueId, exceptionId: HouseOptionValueExceptionId | undefined) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        if (hasValue(exceptionId)) {
          updateHouseValueException.mutate({
            houseId: house.id,
            houseOptionValueExceptionId: exceptionId,
            price: undefined
          })
        } else {
          createHouseValueException.mutate({
            houseId: house.id,
            layoutTypeOptionValueId,
            price: undefined
          })
        }
      }
    }

  const onRevert =
    (houseOptionValueExceptionId: HouseOptionValueExceptionId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteHouseValueException.mutate({ houseId: house.id, houseOptionValueExceptionId })
      }
    }

  const isFormDisabled = false //useMemo(() => !isEditableEntity(house.allowedActions), [house])
  //const isDeleteDisabled = useMemo(() => !isDeletableEntity(house.allowedActions), [house])

  const isLoading = getHouseValueExceptions.isFetching

  return (
    <HouseValueExceptionsStyled>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {hasValue(layoutType) && layoutType.options.length > 0 && (
            <Grid container columnSpacing={{ xs: 6, md: 8 }} rowSpacing={2}>
              {layoutType.options
                .concat()
                .sort((a, b) => a.code.localeCompare(b.code))
                .map((o, oIndex) => (
                  <React.Fragment key={oIndex}>
                    <Grid item xs={12}>
                      <Typography fontWeight="bold">{`${o.code}: ${translateValue(o.name)}`}</Typography>
                    </Grid>
                    {o.values
                      .concat()
                      .sort((a, b) => a.orderNumber - b.orderNumber)
                      .map((v, vIndex) => {
                        const ex = getHouseValueExceptions.data?.find((ex) => v.id === ex.layoutTypeOptionValueId)
                        return (
                          <React.Fragment key={vIndex}>
                            <Grid
                              item
                              xs={6}
                              style={{
                                textDecoration: hasValue(ex) && ex.price == undefined ? 'line-through' : undefined
                              }}
                            >
                              {translateValue(v.name)}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                textDecoration: hasValue(ex) && ex.price == undefined ? 'line-through' : undefined
                              }}
                            >
                              {hasValue(ex) && hasValue(ex.price) ? `${ex.price} (${v.price})` : v.price}
                            </Grid>
                            <Grid item xs={3}>
                              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <ButtonWithEditPriceModalForm
                                  size="small"
                                  houseId={house.id}
                                  layoutTypeOptionValueId={v.id}
                                  price={hasValue(ex) ? Number(ex.price) : Number(v.price)}
                                  exceptionId={ex?.id}
                                  disabled={isFormDisabled}
                                >
                                  <Edit />
                                </ButtonWithEditPriceModalForm>
                                <ButtonWithConfirmation
                                  size="small"
                                  disabled={isFormDisabled}
                                  onConfirmResult={onDelete(v.id, ex?.id)}
                                  confirmTitle={t('pages.admin.house.option_value_exceptions.dialogs.delete.title')}
                                  confirmMessage={t('pages.admin.house.option_value_exceptions.dialogs.delete.message')}
                                >
                                  <Delete />
                                </ButtonWithConfirmation>
                                {hasValue(ex) ? (
                                  <ButtonWithConfirmation
                                    color="error"
                                    size="small"
                                    disabled={isFormDisabled}
                                    onConfirmResult={onRevert(ex.id)}
                                    confirmTitle={t('pages.admin.house.option_value_exceptions.dialogs.revert.title')}
                                    confirmMessage={t(
                                      'pages.admin.house.option_value_exceptions.dialogs.revert.message'
                                    )}
                                  >
                                    <Sync />
                                  </ButtonWithConfirmation>
                                ) : (
                                  <Button color="error" size="small" disabled>
                                    <Sync />
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          </React.Fragment>
                        )
                      })}
                  </React.Fragment>
                ))}
            </Grid>
          )}
          {hasValue(layoutType) &&
            layoutType.options.length === 0 &&
            t('pages.admin.house.option_value_exceptions.messages.no_items')}
        </>
      )}
    </HouseValueExceptionsStyled>
  )
}
