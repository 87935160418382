import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { hasValue } from '@digital-magic/ts-common-utils'
import { PriceNumber } from '@api/endpoints/types'
import {
  HouseId,
  HouseOptionValueExceptionId,
  useCreateHouseOptionValueException,
  useUpdateHouseOptionValueException
} from '@api/endpoints/buildings/houses'
import { LayoutTypeOptionValueId } from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Button, ButtonProps } from '@controls/Button'
import { Form, FormTextField, useFormTyped } from '@controls/Form'

const HouseValueExceptionsFormValues = z.object({
  layoutTypeOptionValueId: LayoutTypeOptionValueId,
  price: PriceNumber
})
type HouseValueExceptionsFormValues = z.infer<typeof HouseValueExceptionsFormValues>

type Props = Omit<ButtonProps, 'onClick'> & {
  price: PriceNumber
  houseId: HouseId
  layoutTypeOptionValueId: LayoutTypeOptionValueId
  exceptionId: HouseOptionValueExceptionId | undefined
  onConfirmResult?: (result: boolean) => void
}

export const ButtonWithEditPriceModalForm: React.FC<Props> = ({
  houseId,
  layoutTypeOptionValueId,
  exceptionId,
  price,
  onConfirmResult,
  children,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const [dialogVisible, setDialogVisible] = React.useState(false)

  const createHouseValueException = useCreateHouseOptionValueException({ onError: defaultErrorHandler })
  const updateHouseValueException = useUpdateHouseOptionValueException({ onError: defaultErrorHandler })

  const onSubmit = (values: HouseValueExceptionsFormValues): void => {
    if (hasValue(exceptionId)) {
      updateHouseValueException.mutate(
        { ...values, houseId, houseOptionValueExceptionId: exceptionId },
        { onSuccess: () => handleClose(true) }
      )
    } else {
      createHouseValueException.mutate({ ...values, houseId }, { onSuccess: () => handleClose(true) })
    }
  }

  const form = useFormTyped({
    resolver: HouseValueExceptionsFormValues,
    onSubmit
  })

  const handleClose = (value: boolean): void => {
    setDialogVisible(false)
    if (onConfirmResult) {
      onConfirmResult(value)
    }
  }

  const handleCancelButtonClick: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    handleClose(false)
  }

  const isFormDisabled = createHouseValueException.isLoading

  const handleOnClick: HtmlMouseButtonEventHandler = (e) => {
    e.stopPropagation()
    form.reset({ layoutTypeOptionValueId: layoutTypeOptionValueId, price: price })
    setDialogVisible(true)
  }

  return (
    <>
      <Button {...buttonProps} onClick={handleOnClick}>
        {children}
      </Button>
      <Dialog
        open={dialogVisible}
        onClose={handleCancelButtonClick}
        disableEscapeKeyDown={isFormDisabled}
        //PaperProps={{ sx: { height: 300 } }}
        aria-labelledby="modal-title"
      >
        <Form f={form}>
          <DialogTitle>{t('pages.admin.house.option_value_exceptions.form.title')}</DialogTitle>
          <DialogContent>
            <Box marginTop={2} marginBottom={2}>
              <FormTextField
                name={form.names.price}
                type="number"
                label={t('pages.admin.house.option_value_exceptions.form.field.price')}
                disabled={isFormDisabled}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="success" type="submit" disabled={isFormDisabled}>
              {t('global.buttons.submit')}
            </Button>
            <Button onClick={handleCancelButtonClick} color="error" disabled={isFormDisabled}>
              {t('global.buttons.close')}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
